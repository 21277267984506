/** @format */

import * as React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import CareEditIcon from '../../../assets/CardEditIcon';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { PatientCareGiverStatusTypeDto, PatientCareGiverType } from '../../../types/PatientCareGiver';

import FormFields from '../../common/form-fields/FormFields';
import CustomLoading from '../../common/custom-loading/CustomLoading';

import { useAppSelector } from '../../../app/hooks';

import { PHONE_NUMBER_INPUT_COMPONENT, TEXT_INPUT_COMPONENT } from '../../../constants/PatientEnrollment';
import { PatientCareGiverPostSchema } from '../../../validations/patient-care-giver.joi';
import _ from 'underscore';
import { ConfirmationDialog } from '../../common/confirmation-dialog/ConfirmationDialog';

interface AddCaregiverDetailsProps {
  isOpen: boolean;
  handleClickClose: () => void;

  isUpdatingCareGiver: boolean;
  handleSubmit: (careGiver: Partial<PatientCareGiverType>) => void;

  careGiverDetails?: Partial<PatientCareGiverType>;
}

const AddCaregiverDetails: React.FunctionComponent<AddCaregiverDetailsProps> = (props) => {
  const userRegion = useAppSelector((state) => state.userRegion);

  const [name, setName] = React.useState<string>('');
  const [nameError, setNameError] = React.useState<string>('');

  const [email, setEmail] = React.useState<string>('');
  const [emailError, setEmailError] = React.useState<string>('');

  const [phoneNumber, setPhoneNumber] = React.useState<string>('');
  const [phoneNumberError, setPhoneNumberError] = React.useState<string>('');

  const [countryDetails, setCountryDetails] = React.useState<{ dialCode?: string; countryCode: string }>({ countryCode: userRegion.countryCode?.toLowerCase() });

  const handleSubmit = () => {
    let countryCode = countryDetails?.dialCode;

    if (_.isEmpty(countryCode) && props.careGiverDetails?.countryCode) {
      countryCode = props.careGiverDetails?.countryCode;
    }

    const reducedPhone = countryCode ? phoneNumber.replace(countryCode, '') : phoneNumber;
    const payload = { name, email, phoneNumber: reducedPhone, countryCode };

    const errors = PatientCareGiverPostSchema.validate(payload, { abortEarly: false, allowUnknown: true });

    if (!_.isEmpty(errors.error)) {
      console.log('ERRORS', errors);

      let nameError = '';
      let eamilError = '';
      let phoneNumberError = '';

      errors.error?.details.forEach((item) => {
        if (item.path.includes('name')) {
          nameError = item.message;
        }
        if (item.path.includes('email')) {
          eamilError = item.message;
        }
        if (item.path.includes('phoneNumber')) {
          phoneNumberError = item.message;
        }
      });

      setNameError(nameError);
      setEmailError(eamilError);
      setPhoneNumberError(phoneNumberError);

      return;
    }

    setNameError('');
    setEmailError('');
    setPhoneNumberError('');

    props.handleSubmit(payload);
  };

  React.useEffect(() => {
    if (props.careGiverDetails?.email) {
      setEmail(props.careGiverDetails?.email);
    }
    if (props.careGiverDetails?.name) {
      setName(props.careGiverDetails?.name);
    }

    if (props.careGiverDetails?.countryCode && props.careGiverDetails?.phoneNumber) {
      setPhoneNumber(props.careGiverDetails?.countryCode + props.careGiverDetails?.phoneNumber);
    } else if (props.careGiverDetails?.phoneNumber) {
      setPhoneNumber(props.careGiverDetails?.phoneNumber);
    }

    if (props.careGiverDetails?.countryCode) {
      // setCountryDetails({ dialCode: props.careGiverDetails?.countryCode } as any);
    }
  }, [props.careGiverDetails]);

  console.log('SDKJNCKSDJCKJDSNCJDSNCDSJKNCJKDS PHONE', phoneNumber, props.careGiverDetails, countryDetails);

  return (
    <Dialog fullWidth maxWidth='xs' onClose={props.handleClickClose} open={props.isOpen}>
      <DialogTitle>
        <Grid item xs={12} container alignItems='center' justifyContent='space-between'>
          <Typography variant='fontSemiBold16' color='#33425B'>
            Enroll Caregiver
          </Typography>

          <IconButton onClick={props.handleClickClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ '& .react-tel-input': { '& input': { width: '100%', height: '49px' } } }}>
        <FormFields
          id='name'
          componentType={TEXT_INPUT_COMPONENT}
          label={
            <Typography variant='fontReg14' color='#5C6A90'>
              Name{' '}
              <Box component='span' sx={{ color: '#ff0000 !important' }}>
                *
              </Box>
            </Typography>
          }
          fullWidth
          input={{
            disabled: props.isUpdatingCareGiver,
            placeholder: 'Enter Name',
            value: name,
            onChange: (event) => setName(event.target.value),
            errorMessage: nameError,
          }}
        />

        <FormFields
          id='email'
          componentType={TEXT_INPUT_COMPONENT}
          label={
            <Typography variant='fontReg14' color='#5C6A90'>
              Email
            </Typography>
          }
          fullWidth
          input={{
            disabled: props.isUpdatingCareGiver,
            placeholder: 'Enter Email ID',
            value: email,
            onChange: (event) => setEmail(event.target.value),
            errorMessage: emailError,
          }}
        />

        <FormFields
          id='email'
          componentType={PHONE_NUMBER_INPUT_COMPONENT}
          label={
            <Typography variant='fontReg14' color='#5C6A90'>
              Mobile Number
            </Typography>
          }
          fullWidth
          input={{
            disabled: props.isUpdatingCareGiver,
            placeholder: 'Enter Phone Number',
            value: phoneNumber,
            countryCode: countryDetails?.countryCode,
            onChange: (value, country: any) => {
              setPhoneNumber(value);
              setCountryDetails(country);
            },
            errorMessage: phoneNumberError,
          }}
        />

        <LoadingButton onClick={handleSubmit} disabled={props.isUpdatingCareGiver} loading={props.isUpdatingCareGiver} fullWidth color='primary' variant='contained'>
          <Typography variant='fontSemiBold16'>Enroll</Typography>
        </LoadingButton>
      </DialogContent>
    </Dialog>
  );
};

interface CaregiversDetailsProps {
  isLoading: boolean;

  careGivers: PatientCareGiverType[];

  isUpdatingCareGiver: boolean;
  handleSubmitCreateNewCareGiver: (careGiver: Partial<PatientCareGiverType>) => void;

  handleSubmitUpdateCareGiver: (careGiverId: string, payload: Partial<PatientCareGiverType>) => void;

  handleSubmitDeleteCareGiver: (careGiverId: string) => void;

  handleSubmitCareGiverApproval: (careGiverId: string, status: PatientCareGiverStatusTypeDto) => void;

  handleClickResetPassword: (careGiverId: string) => void;
}

const CaregiversDetails: React.FunctionComponent<CaregiversDetailsProps> = (props) => {
  const [openAddCaregiverModal, setOpenAddCaregiverModal] = React.useState<boolean>(false);
  const [editCareGiverDetails, setEditCareGiverDetails] = React.useState<Partial<PatientCareGiverType> | null>(null);

  const [openDeleteCaregiverModal, setOpenDeleteCaregiverModal] = React.useState<boolean>(false);
  const [deleteCareGiverDetails, setDeleteCareGiverDetails] = React.useState<Partial<PatientCareGiverType> | null>(null);

  const toggleAddCaregiverModal = () => {
    setOpenAddCaregiverModal((prev) => {
      if (prev) {
        setEditCareGiverDetails(null);
      }
      return !prev;
    });
  };

  const toggleDeleteCaregiverModal = () => {
    setOpenDeleteCaregiverModal((prev) => {
      if (prev) {
        setDeleteCareGiverDetails(null);
      }
      return !prev;
    });
  };

  const handleSubmitNewCareGiver = async (payload: Partial<PatientCareGiverType>) => {
    if (!_.isEmpty(editCareGiverDetails) && editCareGiverDetails?.careGiverId) {
      await props.handleSubmitUpdateCareGiver(editCareGiverDetails?.careGiverId, payload);
    } else {
      await props.handleSubmitCreateNewCareGiver(payload);
    }
    toggleAddCaregiverModal();
  };

  const handleSubmitDeleteCareGiver = async () => {
    if (!_.isEmpty(deleteCareGiverDetails) && deleteCareGiverDetails?.careGiverId) {
      await props.handleSubmitDeleteCareGiver(deleteCareGiverDetails?.careGiverId);
    }
    toggleDeleteCaregiverModal();
  };

  const handleClickEditCareGiver = async (payload: Partial<PatientCareGiverType>) => {
    toggleAddCaregiverModal();
    setEditCareGiverDetails(payload);
  };

  const handleClickResetPassword = async (payload: Partial<PatientCareGiverType>) => {
    if (payload.careGiverId) {
      props.handleClickResetPassword(payload.careGiverId);
    }
  };

  const handleClickDeleteCareGiver = async (payload: Partial<PatientCareGiverType>) => {
    toggleDeleteCaregiverModal();
    setDeleteCareGiverDetails(payload);
  };

  const handleClickCareGiverApproval = (item: Partial<PatientCareGiverType>, status: PatientCareGiverStatusTypeDto) => {
    if (item.careGiverId) {
      props.handleSubmitCareGiverApproval(item.careGiverId, status);
    }
  };

  const _renderCareGiverItem = (item: PatientCareGiverType, index: number) => {
    if (item.status === 'PENDING') {
      return (
        <Grid key={index} item xs={12} container gap={1} sx={{ bgcolor: '#FFFFFF', p: 1, borderRadius: '4px', border: '1px solid #D5D5D6' }}>
          <Grid item xs={12} container justifyContent='space-between'>
            <Typography variant='fontReg14' color='#000'>
              Pending Approval
            </Typography>
          </Grid>
          <Grid item xs={5.5}>
            <Typography variant='fontReg14' color='#9bb4cc' pr={1}>
              name:{' '}
            </Typography>
            <Typography variant='fontReg14' color='#000'>
              {item.name}
            </Typography>
          </Grid>
          <Grid item xs={5.5}>
            <Typography variant='fontReg14' color='#9bb4cc' pr={1}>
              Mobile Number:{' '}
            </Typography>
            <Typography variant='fontReg14' color='#000'>
              {!item.countryCode?.includes('+') && '+'}
              {item.countryCode}-{item.phoneNumber}
            </Typography>
          </Grid>
          <Grid item xs={5.5}>
            <Typography variant='fontReg14' color='#9bb4cc' pr={1}>
              Email ID:{' '}
            </Typography>
            <Typography variant='fontReg14' color='#000'>
              {item.email}
            </Typography>
          </Grid>
          <Grid item xs={12} container gap={3} alignItems='center'>
            <Grid item xs={5.5}>
              <Button onClick={() => handleClickCareGiverApproval(item, 'REJECTED')} fullWidth variant='outlined'>
                Reject
              </Button>
            </Grid>
            <Grid item xs={5.5}>
              <Button onClick={() => handleClickCareGiverApproval(item, 'ACTIVE')} fullWidth variant='contained'>
                Approve
              </Button>
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid key={index} item xs={12} container gap={1} sx={{ bgcolor: '#FFFFFF', p: 1, borderRadius: '4px', border: '1px solid #D5D5D6' }}>
        <Grid item xs={12} container justifyContent='space-between'>
          <Typography variant='fontReg14' color='#000'>
            {item.name}
          </Typography>
          {['DEACTIVATED', 'REJECTED'].includes(item.status) && (
            <Chip
              variant='outlined'
              color='error'
              label={
                <Typography variant='fontReg12' color='#000'>
                  {item.status === 'REJECTED' ? 'Rejected' : 'Deleted'}
                </Typography>
              }
            />
          )}
          {['ACTIVE'].includes(item.status) && (
            <Grid item xs='auto' container gap={2}>
              <Button variant='text' onClick={() => handleClickResetPassword(item)}>
                <Typography color='#006EC7' variant='fontReg12'>
                  Reset Password
                </Typography>
              </Button>
              <IconButton onClick={() => handleClickEditCareGiver(item)}>
                <CareEditIcon />
              </IconButton>
              <IconButton onClick={() => handleClickDeleteCareGiver(item)}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
        <Grid item xs={5.5}>
          <Typography variant='fontReg14' color='#9bb4cc' pr={1}>
            Username:{' '}
          </Typography>
          <Typography variant='fontReg14' color='#000'>
            {item.username}
          </Typography>
        </Grid>
        <Grid item xs={5.5}>
          <Typography variant='fontReg14' color='#9bb4cc' pr={1}>
            Mobile Number:{' '}
          </Typography>
          <Typography variant='fontReg14' color='#000'>
            {!item.countryCode?.includes('+') && '+'}
            {item.countryCode}-{item.phoneNumber}
          </Typography>
        </Grid>
        <Grid item xs={5.5}>
          <Typography variant='fontReg14' color='#9bb4cc' pr={1}>
            Email ID:{' '}
          </Typography>
          <Typography variant='fontReg14' color='#000'>
            {item.email}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const statusAccordian = [
    {
      title: 'Pending Approval',
      data: props.careGivers?.filter((item) => item.status === 'PENDING'),
    },
    {
      title: 'Active',
      data: props.careGivers?.filter((item) => item.status === 'ACTIVE'),
    },
    {
      title: 'Deleted / Rejected',
      data: props.careGivers?.filter((item) => item.status === 'DEACTIVATED' || item.status === 'REJECTED'),
    },
  ];

  return (
    <Grid item xs={12} xl={10} container sx={{ mx: 'auto' }}>
      {props.isLoading && <CustomLoading />}

      <Grid item xs={12} container alignItems='center' justifyContent='space-between'>
        <Typography variant='fontSemiBold14' color='#33425B'>
          Caregivers
        </Typography>
        <Button variant='outlined' color='secondary' endIcon={<AddIcon />} onClick={toggleAddCaregiverModal}>
          <Typography variant='fontSemiBold14' color='#3E4685'>
            Enroll Caregiver
          </Typography>
        </Button>
      </Grid>

      <Grid item xs={12} my={1.5}>
        <Typography variant='fontReg14' color='#5C6A90'>
          One caregiver can be added. To add more, contact support*
        </Typography>
      </Grid>

      {openAddCaregiverModal && (
        <AddCaregiverDetails
          isOpen={openAddCaregiverModal}
          handleClickClose={toggleAddCaregiverModal}
          isUpdatingCareGiver={props.isUpdatingCareGiver}
          careGiverDetails={editCareGiverDetails || {}}
          handleSubmit={handleSubmitNewCareGiver}
        />
      )}

      <Grid item xs={12} container mt={3} justifyContent='center'>
        {statusAccordian.map((item) => {
          if (item.data?.length > 0) {
            return (
              <Accordion sx={{ width: '100%', background: '#FAFBFC', border: '1px solid #D7E1EB' }} elevation={0}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1-content' id='panel1-header'>
                  <Typography variant='fontSemiBold16'>{item.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid item xs={12} container gap={2}>
                    {item.data.map(_renderCareGiverItem)}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            );
          }
          return null;
        })}

        {props.careGivers.length <= 0 && (
          <Typography color='#C6C6C6' variant='fontSemiBold16' mt='25%' mx='auto'>
            Click on "Add Caregiver" button to add caregivers for the patient
          </Typography>
        )}
      </Grid>

      {openDeleteCaregiverModal && (
        <ConfirmationDialog
          isLoading={props.isUpdatingCareGiver}
          onClickConfirm={handleSubmitDeleteCareGiver}
          isOpen={openDeleteCaregiverModal}
          onClose={toggleDeleteCaregiverModal}
          title='Delete Caregiver'
          description={`Are you sure you want to delete ${deleteCareGiverDetails?.name}?`}
        />
      )}
    </Grid>
  );
};

export default CaregiversDetails;
