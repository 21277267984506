/** @format */

import React from 'react';
import _ from 'underscore';
import { Box } from '@mui/system';
import Stack from '@mui/material/Stack';
import { Typography, Grid, useTheme } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { vitalsUnitConverters } from 'vitals-convertor';

// import LineChart from '../../common/charts/LineChart';

import TrendLineChart from '../../common/charts/TrendLineChart';

// Types
import { LineChartsDataType } from '../../../types/LineChart.types';

// Utils.
import { customConvertFloatToIntV2 } from '../../../utils/patient-vitals-utils';

// Types.
import { BasicDetailsType } from '../../../types/Patients.types';

// Css
import './PatientTrendlineWeight.scss';

import { useAppSelector } from '../../../app/hooks';

import { USER_VITALS_UNIT_MAPPING } from '../../../constants/PatientVitals';

interface PatientTrendLineWeightInterface {
  id: 'WEIGHT' | 'BLOOD_PRESSURE' | 'HEART_RATE' | 'EGFR' | 'POTASSIUM';
  data: LineChartsDataType[];
  dryWeight: string;
  patientBasicDetails: BasicDetailsType;
  weightChange: any;
}

const PatientTrendlineWeight: React.FC<PatientTrendLineWeightInterface> = (props: PatientTrendLineWeightInterface) => {
  const appTheme = useTheme();

  const configState = useAppSelector((state) => state.config);
  const userUnit = configState.vitalsUnit[USER_VITALS_UNIT_MAPPING.WEIGHT as any];
  let updatedDryWeight = props.dryWeight;
  const dryWeight = vitalsUnitConverters({ type: 'WEIGHT', measurements: [{ value: props.dryWeight, type: 'WEIGHT', unit: 'lbs' }] }, userUnit);
  updatedDryWeight = dryWeight.measurements[0].value;

  if (!props.data || props.data?.length <= 0) {
    return (
      <div id='linechart-parent' style={{ minWidth: 'fit-content' }}>
        <Typography variant='fontReg14' fontFamily='Lato' color={appTheme.palette.customColor.text}>
          No Data to display
        </Typography>
      </div>
    );
  }

  const VITALS_MAPPING: any = {
    WEIGHT: 'Weight Change',
  };

  let weightChange2Days = !_.isNaN(parseFloat(props.weightChange?.weightChangeWithIn7Days?.value))
    ? vitalsUnitConverters({ type: 'WEIGHT', measurements: [{ value: props.weightChange?.weightChangeWithIn7Days?.value, type: 'WEIGHT', unit: 'lbs' }] }, userUnit)
    : {};
  let weightChangeAfter2Days = !_.isNaN(parseFloat(props.weightChange?.weightChangeAfter7Days?.value))
    ? vitalsUnitConverters({ type: 'WEIGHT', measurements: [{ value: props.weightChange?.weightChangeAfter7Days?.value, type: 'WEIGHT', unit: 'lbs' }] }, userUnit)
    : {};

  if (!_.isEmpty(weightChange2Days)) {
    weightChange2Days = _.get(weightChange2Days, ['measurements', '0', 'value'], null) || null;
  }

  if (!_.isEmpty(weightChangeAfter2Days)) {
    weightChangeAfter2Days = _.get(weightChangeAfter2Days, ['measurements', '0', 'value'], null) || null;
  }

  let name = '';

  if (props.patientBasicDetails && props.patientBasicDetails?.firstName && props.patientBasicDetails?.lastName) {
    name = props.patientBasicDetails?.firstName + ' ' + props.patientBasicDetails?.lastName;
  }

  return (
    <Stack id='weight-change' gap={2}>
      {name.length !== 0 ? (
        <Typography variant='fontBold14' color={appTheme.palette.customColor.text}>
          {VITALS_MAPPING[props.id] || 'Vitals Reading'} - {name} / {props.patientBasicDetails?.patientId}
        </Typography>
      ) : (
        <Typography variant='fontBold14' color={appTheme.palette.customColor.text}>
          {VITALS_MAPPING[props.id] || 'Vitals Reading'} - {props.patientBasicDetails?.patientId}
        </Typography>
      )}
      <Grid item className='weight-change-container'>
        <TrendLineChart
          id={'trendline-chart-weight'}
          vitalName={props.id}
          data={props.data || []}
          dimensions={{ width: 350, height: 180 }}
          viewBox={{ minX: 0, minY: 0, width: 0, height: 0 }}
          margin={{ top: 0, right: 0, bottom: 0, left: 35 }}
          range={false}
          lowerBound={''}
          upperBound={''}
          continuousLine={true}
          onClickSetContinuousLine={console.info}
          color={'#D80914'}
          toggleDots={false}
          toggleTooltip={false}
          toggleVerticalPipe={false}
          toggleXAxisGrid={false}
          toggleYAxisGrid={false}
          tickSize={0}
          toggleMedianLine={true}
          yAxisTicks={5}
          xAxisTicks={0}
          xAxisTickSize={0}
          yAxisTickSize={0}
          timeFormat={'%b %d'}
          xAxisNice={0}
          yAxisNice={0}
          xAxisPosition={'BOTTOM'}
          toggleSVGIcon={false}
          toggleSVGIconTooltip={false}
          rangeXAxisOffset={15}
          rangeYAxisOffset={15}
          scale={'SCALE_POINT'}
        />
      </Grid>

      <Grid item>
        <Grid item xs alignItems='center' mt={2} display='flex'>
          <Typography variant='fontSemiBold14'>{'Dry Weight: '}</Typography>

          {!props.dryWeight ? (
            <Typography sx={{ marginLeft: '5px' }}>NA</Typography>
          ) : (
            <Box sx={{ marginLeft: '5px', padding: '2px 8px', borderRadius: '5px', backgroundColor: '#f5f9fe', border: '1px solid #9bb4cc' }}>
              <Typography color='primary'>{updatedDryWeight + ' ' + userUnit}</Typography>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} container mt={2} flexDirection='column'>
          <Typography component='div' variant='fontReg14' color='GrayText'>
            Weight change during 2 to 7 days: <b>{!_.isNaN(parseFloat(weightChange2Days)) ? `${customConvertFloatToIntV2(weightChange2Days)} ${userUnit}` : 'NA'}</b>
          </Typography>
          <Typography my={2} component='div' variant='fontReg14' color='GrayText'>
            Weight change over 7 days: <b>{!_.isNaN(parseFloat(weightChangeAfter2Days)) ? `${customConvertFloatToIntV2(weightChangeAfter2Days)} ${userUnit}` : 'NA'}</b>
          </Typography>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default PatientTrendlineWeight;
