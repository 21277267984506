/** @format */
import * as React from 'react';
import * as _ from 'underscore';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Accordion from '@mui/material/Accordion';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Service
import {
  createTasksCareTeamApi,
  createPatientDailyTasksApi,
  createTasksMedicationChangeApi,
  generatePatientAppLoginResetAppCredsLink,
  uplaodPatientFile,
  getPatientAppCreds,
  fetchPatientOmronDetails,
  updatePatientOmronDetails,
  fetchPatientTreatmentDevicesPreferences,
  createPatientTreatmentDevicesPreferences,
  updatePatientTreatmentDevicesPreferences,
  deletePatientOmronDetails,
  getPatientCareGiverDetails,
  createNewPatientCareGiverDetails,
  updatePatientCareGiverDetails,
  deletePatientCareGiverDetails,
  updatePatientCareGiverApproval,
  generatePatientCareGiverLoginResetAppCredsLink,
} from '../../../service/patient-notification-preferences';

// Types
import { PatientCareGiverStatusTypeDto, PatientCareGiverType } from '../../../types/PatientCareGiver';
import { PatientAppCredentials } from '../../../types/PatientAppCreds.types';

// Hooks
import { useAppDispatch } from '../../../app/hooks';
import { clearToast, setToast } from '../../../reducers/toast-reducer/ToastReducer';

// Components
import TestingConfig from './TestingConfig';
import PatientAppLogin from './PatientAppLogin';
import CaregiversDetails from './CaregiversDetails';
import OmronSettingPannel from './OmronSettingPannel';
import PatientDeviceManagement from './PatientDeviceManagement';
import PatientRuleList from '../../patient-rule/PatientRuleList';
import PatientRuleThreshold from '../../patient-rule/PatientRuleThreshold';
import CustomTabsPanel, { CustomTabsPanelType } from '../../common/custom-tabs/CustomTabPanel';
import TreatmentPreferences, { PatientTreatmentDevicesPreferencesType } from './TreatmentPreferences';

import { PatientProfileContext } from '../../../pages/patient-profile/PatientProfileContext';
import { forceTriggerFitbitSync, forceTriggerOmronDataSync } from '../../../service/patient-titrations-plan';

import { PATIENT_RULE_ACTION_KIND_MEDICATION_CHANGE } from '../../../constants/PatientRules';

interface ProfileSettingPanelProps {
  openDrawer: boolean;
  toggleSettingPanel: () => void;

  isEnrolmentContext?: boolean;

  displayTabsLayout?: boolean;

  displayModal?: boolean;

  showPatientAlertsThresholdTab?: boolean;
  showPatientAlertsRulesTab?: boolean;
  showAlertThresholds?: boolean;
  resetLocationValue?: () => void;

  openCareGiverDetails?: boolean;
}

const ProfileSettingPanelSidebar: React.FunctionComponent<ProfileSettingPanelProps> = (props) => {
  const reduxDispatch = useAppDispatch();

  const { patientSourceId }: any = useParams();
  const [isPatientAppLoginUpdating, setIsPatientAppLoginUpdating] = React.useState<boolean>(false);

  const [isOmronUpdating, setIsOmronUpdating] = React.useState<boolean>(false);
  const [omronDetails, setOmronDetails] = React.useState<any>({});

  const [isPatientTreatmentDevicesPreferencesUpdating, setIsPatientTreatmentDevicesPreferencesUpdating] = React.useState<boolean>(false);
  const [patientTreatmentDevicesPreferences, setPatientTreatmentDevicesPreferences] = React.useState<PatientTreatmentDevicesPreferencesType | null>(null);

  const [patientAppCreds, setPatientAppCreds] = React.useState<PatientAppCredentials>({} as PatientAppCredentials);
  const [isLoadingPatientAppCreds, setIsLoadingPatientAppCreds] = React.useState<boolean>(false);

  const [patientUplaodFile, setPatientUploadFile] = React.useState<any>();
  const [value, setvalue] = React.useState<any>('Mobile App Credentials');

  const {
    patientRuleThreshold,
    isUpdatingPatientRuleThreshold,
    handleSubmitUpdatePatientRuleThreshold,
    patientRules,
    isUpdatingPatientRule,
    handleSubmitChangeDefaultRules,
    handleSubmitNewPatientRule,
    handleSubmitUpdatePatientRule,
    handleSubmitDeletePatientRule,
  } = React.useContext(PatientProfileContext);

  React.useEffect(() => {
    handlDisplayPanelComponent('Caregivers details');
  }, []);

  const fetchPatientCareGivers = useQuery({
    queryKey: ['getPatientCareGiverDetails', patientSourceId],
    queryFn: async (): Promise<PatientCareGiverType[]> => {
      return await getPatientCareGiverDetails(patientSourceId!);
    },
  });

  const createnewPatientCareGiversDetailsMutate = useMutation({
    mutationFn: async (payload: Partial<PatientCareGiverType>) => {
      return await createNewPatientCareGiverDetails(patientSourceId!, payload);
    },
    onSuccess: () => {
      reduxDispatch(setToast({ message: 'Successfully Enrolled Care Giver', code: 'success', isOpen: true }));
    },
    onError: (err: any) => {
      console.log('ERROR createnewPatientCareGiversDetailsMutate', err);
      let errorMessage = 'Failed to enrolled care giver';
      if (err?.response?.data?.statusCode === 403) {
        errorMessage = err?.response?.data?.error;
      }
      reduxDispatch(setToast({ message: errorMessage, code: 'error', isOpen: true }));
    },
  });

  const updatePatientCareGiversDetailsMutate = useMutation({
    mutationFn: async ({ careGiverId, payload }: { careGiverId: string; payload: Partial<PatientCareGiverType> }) => {
      return await updatePatientCareGiverDetails(patientSourceId!, careGiverId, payload);
    },
    onSuccess: () => {
      reduxDispatch(setToast({ message: 'Successfully Upated Care Giver Details', code: 'success', isOpen: true }));
    },
    onError: () => {
      reduxDispatch(setToast({ message: 'Failed to update care giver details', code: 'error', isOpen: true }));
    },
  });

  const deletePatientCareGiversDetailsMutate = useMutation({
    mutationFn: async ({ careGiverId }: { careGiverId: string }) => {
      return await deletePatientCareGiverDetails(patientSourceId!, careGiverId);
    },
    onSuccess: () => {
      reduxDispatch(setToast({ message: 'Successfully Deleted Care Giver Details', code: 'success', isOpen: true }));
    },
    onError: () => {
      reduxDispatch(setToast({ message: 'Failed to delete care giver details', code: 'error', isOpen: true }));
    },
  });

  const updatePatientCareGiversApprovalMutate = useMutation({
    mutationFn: async ({ careGiverId, status }: { careGiverId: string; status: PatientCareGiverStatusTypeDto }) => {
      await updatePatientCareGiverApproval(patientSourceId!, careGiverId, status);
      reduxDispatch(setToast({ message: `Successfully ${status === 'ACTIVE' ? 'Approved' : 'Rejected'} Care Giver`, code: 'success', isOpen: true }));
      return;
    },
    onError: () => {
      reduxDispatch(setToast({ message: 'Failed to update care giver details', code: 'error', isOpen: true }));
    },
  });

  const generateResetPasswordPatientCareGiversMutate = useMutation({
    mutationFn: async ({ careGiverId }: { careGiverId: string }) => {
      const resetTokenURL = await generatePatientCareGiverLoginResetAppCredsLink(patientSourceId, careGiverId);
      const id = new Date().getTime();

      reduxDispatch(
        setToast({
          id,
          message: 'Preferences Updated Successfully.',
          code: 'success',
          html: (
            <Grid container alignItems='center' justifyContent='space-between'>
              <Grid item xs={9.8}>
                <Typography variant='fontBold18'>Reset token link generated successfully</Typography>
              </Grid>
              <Grid item xs={2}>
                <Button
                  onClick={() => {
                    navigator.clipboard.writeText(resetTokenURL.resetUrl).then(
                      function () {
                        console.error('Async: Copying to clipboard was successful!');
                      },
                      function (err) {
                        console.error('Async: Could not copy text: ', err);
                      },
                    );
                    reduxDispatch(clearToast({ id }));
                  }}>
                  Copy
                </Button>
              </Grid>
            </Grid>
          ),
          noTimeout: true,
          isOpen: true,
        }),
      );
      return;
    },
    onError: () => {
      reduxDispatch(setToast({ message: 'Failed to update care giver details', code: 'error', isOpen: true }));
    },
  });

  /* API */
  const getPatientTreatmentDevicesPreferences = async (patientSourceId: string) => {
    try {
      const response = await fetchPatientTreatmentDevicesPreferences(patientSourceId);
      if (!_.isEmpty(response)) {
        setPatientTreatmentDevicesPreferences(response);
      }
    } catch (error) {
      console.error('Error', error);
    }
  };

  const getPatientOmronDetails = async (patientSourceId: string) => {
    try {
      const response = await fetchPatientOmronDetails(patientSourceId);
      setOmronDetails(response);
    } catch (error) {
      console.error('Error', error);
    }
  };

  const fetchPatientAppCreds = async (patientSourceId: string) => {
    try {
      setIsLoadingPatientAppCreds(true);
      const response = await getPatientAppCreds(patientSourceId);
      setPatientAppCreds(response);
    } catch (error) {
      console.error('Error', error);
    } finally {
      setIsLoadingPatientAppCreds(false);
    }
  };

  const handleSubmitOmronSync = async (startDate: string) => {
    try {
      await forceTriggerOmronDataSync(patientSourceId, startDate);
      reduxDispatch(setToast({ message: 'Successfully triggred the omron sync', code: 'success', isOpen: true }));
    } catch (err) {
      console.error('ERR', err);
      reduxDispatch(setToast({ message: 'Error while triggering omron sync', code: 'error', isOpen: true }));
    }
  };

  const handleSubmitFitbitSync = async (startDate: string) => {
    try {
      await forceTriggerFitbitSync(patientSourceId, startDate);
      reduxDispatch(setToast({ message: 'Successfully triggred the fitbit sync', code: 'success', isOpen: true }));
    } catch (err) {
      console.error('ERR', err);
      reduxDispatch(setToast({ message: 'Error while triggering fitbit sync', code: 'error', isOpen: true }));
    }
  };

  const handleOnClickCreateCareTreamTasks = async (date: string) => {
    try {
      await createTasksCareTeamApi(patientSourceId, date);
      reduxDispatch(setToast({ message: 'Care Team Tasks Created Successfully.', code: 'success', isOpen: true }));
    } catch (error) {
      reduxDispatch(setToast({ message: 'Failed.', code: 'error', isOpen: true }));
    }
  };

  const createTasksMedicationChange = async (lastTitrationDate: string, currentDate: string) => {
    try {
      await createTasksMedicationChangeApi(patientSourceId, lastTitrationDate, currentDate);
      reduxDispatch(setToast({ message: 'Medication Changed Successfully.', code: 'success', isOpen: true }));
    } catch (error) {
      reduxDispatch(setToast({ message: 'Failed.', code: 'error', isOpen: true }));
    }
  };

  const createPatientDailyTasks = async (date: string) => {
    try {
      await createPatientDailyTasksApi(patientSourceId, date);

      reduxDispatch(setToast({ message: 'Daily Task Created Successfully.', code: 'success', isOpen: true }));
    } catch (error) {
      reduxDispatch(setToast({ message: 'Failed.', code: 'error', isOpen: true }));
    }
  };

  /* API */

  const handleClickResetPasswordLink = async () => {
    setIsPatientAppLoginUpdating(true);
    try {
      const resetTokenURL = await generatePatientAppLoginResetAppCredsLink(patientSourceId);
      const id = new Date().getTime();

      reduxDispatch(
        setToast({
          id,
          message: 'Preferences Updated Successfully.',
          code: 'success',
          html: (
            <Grid container alignItems='center' justifyContent='space-between'>
              <Grid item xs={9.8}>
                <Typography variant='fontBold18'>Reset token link generated successfully</Typography>
              </Grid>
              <Grid item xs={2}>
                <Button
                  onClick={() => {
                    navigator.clipboard.writeText(resetTokenURL.resetUrl).then(
                      function () {
                        console.error('Async: Copying to clipboard was successful!');
                      },
                      function (err) {
                        console.error('Async: Could not copy text: ', err);
                      },
                    );
                    reduxDispatch(clearToast({ id }));
                  }}>
                  Copy
                </Button>
              </Grid>
            </Grid>
          ),
          noTimeout: true,
          isOpen: true,
        }),
      );
    } catch (error) {
      reduxDispatch(setToast({ message: 'Failed.', code: 'error', isOpen: true }));
    }
    setIsPatientAppLoginUpdating(false);
  };

  const onFileUpload = async () => {
    const formData = new FormData();
    formData.append('file', patientUplaodFile, patientUplaodFile.name);
    try {
      await uplaodPatientFile(patientSourceId, formData);
      reduxDispatch(setToast({ message: 'Successfully uploaded the patient history', code: 'success', isOpen: true }));
    } catch (Err) {
      reduxDispatch(setToast({ message: 'Error while upload the patient history.', code: 'error', isOpen: true }));
    }
  };
  const handlDisplayPanelComponent = (label: string) => {
    setvalue(label);
  };

  const handleOnClickSaveOmronConnectIdAndOmronGroupId = async (omronConnectId: any, omronGroupId: any) => {
    setIsOmronUpdating(true);
    try {
      await updatePatientOmronDetails(patientSourceId, { omronConnectId, omronGroupId });
      reduxDispatch(setToast({ message: 'Successfully updated the omron details.', code: 'success', isOpen: true }));
    } catch (err) {
      reduxDispatch(setToast({ message: 'Failed to update the omron details.', code: 'error', isOpen: true }));
    }
    setIsOmronUpdating(false);
  };

  const handleSubmitDeleteOmronCreds = async () => {
    setIsOmronUpdating(true);
    try {
      await deletePatientOmronDetails(patientSourceId);
      reduxDispatch(setToast({ message: 'Successfully delete the omron details.', code: 'success', isOpen: true }));
      await getPatientOmronDetails(patientSourceId);
    } catch (err) {
      reduxDispatch(setToast({ message: 'Failed to delete the omron details.', code: 'error', isOpen: true }));
    }
    setIsOmronUpdating(false);
  };

  const handleSubmitPatientTreatmentDevicePreferences = async (preferences: any) => {
    setIsPatientTreatmentDevicesPreferencesUpdating(true);
    try {
      if (!_.isEmpty(patientTreatmentDevicesPreferences) && !_.isEmpty(patientTreatmentDevicesPreferences?.type)) {
        await updatePatientTreatmentDevicesPreferences(patientSourceId, preferences);
      } else {
        await createPatientTreatmentDevicesPreferences(patientSourceId, preferences);
      }
      reduxDispatch(setToast({ message: 'Successfully updated the omron details.', code: 'success', isOpen: true }));
    } catch (err) {
      reduxDispatch(setToast({ message: 'Failed to update the omron details.', code: 'error', isOpen: true }));
    }
    setIsPatientTreatmentDevicesPreferencesUpdating(false);
  };

  const handleSubmitNewCareGiver = async (payload: Partial<PatientCareGiverType>) => {
    await createnewPatientCareGiversDetailsMutate.mutateAsync(payload);
    await fetchPatientCareGivers.refetch();
  };

  const handleSubmitEditCareGiver = async (careGiverId: string, payload: Partial<PatientCareGiverType>) => {
    await updatePatientCareGiversDetailsMutate.mutateAsync({ payload, careGiverId });
    await fetchPatientCareGivers.refetch();
  };

  const handleSubmitDeleteCareGiver = async (careGiverId: string) => {
    await deletePatientCareGiversDetailsMutate.mutateAsync({ careGiverId });
    await fetchPatientCareGivers.refetch();
  };

  const handleSubmitCareGiverApproval = async (careGiverId: string, status: PatientCareGiverStatusTypeDto) => {
    await updatePatientCareGiversApprovalMutate.mutateAsync({ careGiverId, status });
    await fetchPatientCareGivers.refetch();
  };

  const handleClickResetPassword = async (careGiverId: string) => {
    await generateResetPasswordPatientCareGiversMutate.mutateAsync({ careGiverId });
  };

  React.useEffect(() => {
    getPatientOmronDetails(patientSourceId);
    fetchPatientAppCreds(patientSourceId);
    getPatientTreatmentDevicesPreferences(patientSourceId);
  }, [patientSourceId]);

  React.useEffect(() => {
    if (props.showAlertThresholds) {
      setvalue('Alert Thresholds');
    }
    props.resetLocationValue && props.resetLocationValue();
  }, [props.showAlertThresholds]);

  const settingTabs: CustomTabsPanelType[] = [
    {
      label: 'Mobile App Credentials',
      panelComponent: (
        <PatientAppLogin
          isLoading={isLoadingPatientAppCreds}
          username={patientAppCreds?.username}
          refreshAppCreds={() => fetchPatientAppCreds(patientSourceId)}
          isResetPasswordLinkLoading={isPatientAppLoginUpdating}
          handleClickResetPasswordLink={handleClickResetPasswordLink}
        />
      ),
    },
    {
      label: 'Treatment',
      panelComponent: (
        <TreatmentPreferences
          handleSubmitPatientTreatmentDevicePreferences={handleSubmitPatientTreatmentDevicePreferences}
          isPatientTreatmentDevicesPreferencesUpdating={isPatientTreatmentDevicesPreferencesUpdating}
          patientTreatmentDevicesPreferences={patientTreatmentDevicesPreferences}
        />
      ),
    },

    {
      label: 'Device Management',
      panelComponent: <PatientDeviceManagement isEnrolmentContext={props.isEnrolmentContext} />,
    },

    {
      label: 'Alert Thresholds',
      hideTab: !props.showPatientAlertsThresholdTab,
      panelComponent: (
        <Box>
          <PatientRuleThreshold
            rules={patientRuleThreshold}
            isUpdatingPatientRuleThreshold={isUpdatingPatientRuleThreshold}
            handleSubmitUpdatePatientRuleThreshold={handleSubmitUpdatePatientRuleThreshold}
          />
        </Box>
      ),
    },

    {
      label: 'Suggestion Rules',
      hideTab: !props.showPatientAlertsRulesTab,
      panelComponent: (
        <PatientRuleList
          itemControlEdit
          isRestrictColumnWidth
          patientRules={patientRules?.filter((item) => item.ruleKind === PATIENT_RULE_ACTION_KIND_MEDICATION_CHANGE) || []}
          isUpdatingPatientRule={isUpdatingPatientRule}
          handleClickChangeRules={handleSubmitChangeDefaultRules}
          handleSubmitNewPatientRule={handleSubmitNewPatientRule}
          handleSubmitUpdatePatientRule={handleSubmitUpdatePatientRule}
          handleSubmitDeletePatientRule={handleSubmitDeletePatientRule}
        />
      ),
    },
    {
      label: 'Omron Management',
      panelComponent: (
        <OmronSettingPannel
          omronDetails={omronDetails}
          isUpdating={isOmronUpdating}
          handleSubmit={handleOnClickSaveOmronConnectIdAndOmronGroupId}
          handleSubmitDeleteOmronCreds={handleSubmitDeleteOmronCreds}
        />
      ),
    },
    {
      label: 'Caregivers details',
      panelComponent: (
        <CaregiversDetails
          careGivers={fetchPatientCareGivers.data || []}
          isLoading={fetchPatientCareGivers.isPending}
          isUpdatingCareGiver={createnewPatientCareGiversDetailsMutate.isPending || updatePatientCareGiversDetailsMutate.isPending || deletePatientCareGiversDetailsMutate.isPending}
          handleSubmitCreateNewCareGiver={handleSubmitNewCareGiver}
          handleSubmitUpdateCareGiver={handleSubmitEditCareGiver}
          handleSubmitDeleteCareGiver={handleSubmitDeleteCareGiver}
          handleSubmitCareGiverApproval={handleSubmitCareGiverApproval}
          handleClickResetPassword={handleClickResetPassword}
        />
      ),
    },
    {
      label: 'Testing Config',
      hideTab: localStorage.getItem('show-testing-config') !== '1' || props.isEnrolmentContext,
      panelComponent: (
        <TestingConfig
          handleOnClickCreateCareTreamTasks={handleOnClickCreateCareTreamTasks}
          handleOnClickCreateTasksMedicationChange={createTasksMedicationChange}
          handleOnClickCreatePatientDailyTasks={createPatientDailyTasks}
          setPatientUploadFile={setPatientUploadFile}
          onFileUpload={onFileUpload}
          handleSubmitOmronSync={handleSubmitOmronSync}
          handleSubmitFitbitSync={handleSubmitFitbitSync}
        />
      ),
    },
  ].filter((item) => !item.hideTab);

  if (props.displayTabsLayout) {
    return (
      <>
        <Grid
          item
          xs={12}
          alignItems='center'
          justifyContent='space-between'
          gap={1}
          sx={{
            '& .MuiAccordionSummary-content-root.Mui-expanded': {
              margin: '0px !important',
            },

            '& .MuiAccordionSummary-root.Mui-expanded': {
              minHeight: '48px !important',
              padding: '6px 8px 6px 9px',
            },

            '& .MuiAccordionSummary-root': {
              margin: '0 !important',
              background: '#eff1ff',
              padding: '6px 8px 6px 9px',

              '& .MuiAccordionSummary-content': {
                margin: '0 !important',
              },
            },
          }}>
          {settingTabs.map((item) => {
            if (item.hideTab) {
              return null;
            }
            return (
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1-content' id='panel1-header'>
                  <Typography color='#33425B'>{item.label}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ px: 0 }}>
                  <Container maxWidth='sm'>{item.panelComponent}</Container>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Grid>
      </>
    );
  }

  if (props.displayModal) {
    return (
      <Drawer
        anchor='right'
        variant='temporary'
        open={props.openDrawer}
        onClose={props.toggleSettingPanel}
        PaperProps={{
          sx: {
            maxWidth: '30rem',
            pt: '7rem',
            '& > .custom-tabs > .tabpanel': {
              px: 3,
              py: 1,
            },
          },
        }}>
        <CustomTabsPanel tabs={settingTabs} />
      </Drawer>
    );
  }

  return (
    <Grid item xs={12} container id='patient-profile-setting'>
      <Grid item xs={3} sx={{ border: '1px solid #9BB4CC', height: '70vh' }}>
        {settingTabs.map((item) => {
          if (item.hideTab) {
            return null;
          }

          return (
            <>
              <Box
                sx={{
                  flexDirection: 'column',
                  padding: '14px',
                  backgroundColor: item.label === value ? '#eff1ff' : 'inherit',
                  margin: '5px',
                }}
                key={item.label}
                onClick={() => handlDisplayPanelComponent(item.label)}>
                <Typography variant='fontReg16' sx={{ cursor: 'pointer' }}>
                  {item.label}
                </Typography>
              </Box>
            </>
          );
        })}
      </Grid>
      <Grid item xs={9}>
        {settingTabs.map((item) => {
          if (item.label === value)
            return (
              <Container fixed key={item.label} sx={{ padding: '0 0 10px 10px' }}>
                {item.panelComponent}
              </Container>
            );
        })}
      </Grid>
    </Grid>
  );
};

export default ProfileSettingPanelSidebar;
